<script>
/* eslint-disable */
import {consultaImoveis, relacionaItensLeilao} from "@/domain/integra/services/resale"
import {ErpSelect} from 'uloc-vue-plugin-erp'
import {UCheckbox, UTable, UTd, UTh, UTr} from "uloc-vue"
import {list} from "@/domain/leiloes/services"
import ERow from '@/components/layout/components/Row'
import ECol from '@/components/layout/components/Col'
import windowInfo from '../windows/info.js'

let listStorage = [
  {label: 'Finalidade', name: 'finalidade', active: true, sortable: false, ordering: 1},
  {label: 'Descrição', name: 'descricao', active: true, sortable: false, ordering: 1},
  {label: 'Endereço', name: 'endereco', active: true, sortable: false, ordering: 1},
  {label: 'Mínimo', name: 'valorMinimo', active: true, sortable: false, ordering: 1},
  {label: 'Último Leilão', name: 'dataLeilao', active: true, sortable: true, ordering: 1},
  {label: 'Lote', name: 'lote', active: true, sortable: false, ordering: 1},
  {label: 'Rgi', name: 'rgi', active: true, sortable: false, ordering: 1},
  {label: 'Matrícula', name: 'matricula', active: true, sortable: false, ordering: 1},
  {label: 'Carteira', name: 'carteira_codigo', active: true, sortable: true, ordering: 1},
  {label: 'Área Total', name: 'area_total', active: true, sortable: false, ordering: 1},
]

let filters = {
  manager: 'bb'
}

export default {
  name: 'WSResaleConsultaLeilao',
  inject: ['page'],
  mixins: [],
  components: {
    ErpSelect,
    UTable,
    UTh,
    UTr,
    UTd,
    UCheckbox,
    ECol,
    ERow
  },
  data() {
    return {
      listStorage: listStorage,
      table: {
        busca: '',
        serverData: [],
        serverPagination: {
          page: 1,
          rowsNumber: 1, // specifying this determines pagination is server-side
          rowsPerPage: 500
        },
        columns: listStorage.map(o => {
          return {
            ...o,
            field: o.name,
            required: o.required || null,
            align: 'left'
          }
        }).concat([
          {
            name: 'options',
            required: true,
            label: '',
            field: 'options',
            sortable: false
          }
        ]),
        filter: '',
        filters: JSON.parse(JSON.stringify(filters)),
        selected: [],
        loading: false
      },
      loading: false,
      seletor: null,
      resultado: null,
      loadingLeiloes: false,
      leilao: null,
      asyncdata: [],
      vendedores: [
        {
          label: 'Banco do Brasil',
          value: 'bb'
        },
        {
          label: 'Embracon',
          value: 'embracon'
        },
        {
          label: 'Emgea',
          value: 'Emgea'
        },
        {
          label: 'JIVE',
          value: 'jive'
        },
        {
          label: 'Poupex',
          value: 'poupex'
        },
        {
          label: 'Banco Master',
          value: 'banco-master'
        },
        {
          label: 'Banco de Brasília',
          value: 'banco-de-brasilia'
        },
        {
          label: 'BRB',
          value: 'brb'
        }
      ]
    }
  },
  computed: {
    visibledColumns() {
      return this.listStorage.filter(item => {
        return item.active
      }).map(item => item.name)
    },
    filteredServerData() {
      let data = this.table.serverData.slice()
      let busca = this.table.filters.pesquisa
      if (busca && String(busca).length > 0) {
        console.log(busca)
        data = data.filter(lt => {
          return String(lt.Placa).toLowerCase().indexOf(busca) > -1 ||
              String(lt.Chassi).toLowerCase().indexOf(busca) > -1
        })
      }
      return data
    }
  },
  mounted() {
    this.page.setActive('consultaLeilao')
    const container = document.querySelector('.u-erp-layout-container')
    !container.classList.contains('app-globalconfig') && container.classList.add('app-globalconfig')
    this.loadLeiloesAtivos()
  },
  beforeDestroy() {
    document.querySelector('.u-erp-layout-container').classList.remove('app-globalconfig')
  },
  methods: {
    abrir(bem) {
      this.windowInfo(bem)
    },
    windowInfo: windowInfo,
    request() {
      this.consultar()
    },
    consultar() {
      this.table.loading = true

      let filters = []
      filters.push(`?manager=${this.table.filters.manager}`)
      filters.push('&page='+this.table.serverPagination.page)
      filters.push('&offset='+this.table.serverPagination.rowsPerPage)

      consultaImoveis(encodeURI(filters.join('')))
          .then(({data}) => {
            console.log(data.imoveis)
            this.table.loading = false
            this.table.serverData = data.imoveis
            this.table.serverPagination.rowsNumber = data.pagination.total_items
            this.table.serverPagination.page = data.pagination.page
            this.table.serverPagination.rowsPerPage = data.pagination.offset
            this.loading = false
            this.resultado = data
            console.log(data)
          })
          .catch(error => {
            this.table.loading = false
            this.alertApiError(error)
          })
    },
    infoBem(row) {
      let comitente = row.bem.comitente
      let comitenteNome = comitente.nome
      comitenteNome = comitenteNome.split(' ')
      comitenteNome = comitenteNome.shift()
      return `${row.bem.id}/${row.bem.comitente.id}/${comitenteNome}`
    },
    loadLeiloesAtivos() {
      this.loading = true
      console.log('Loading leiloes')
      list(100, 1, '&status=0,1,2,3,4&sortBy=dataProximoLeilao&descending=false') // TODO: Criar filtro de ativo na requisição
          .then((response) => {
            this.loading = false
            if (!Array.isArray(response.data.result)) {
              return
            }
            let list = response.data.result.map((obj) => {
              return {
                ...obj,
                label: obj.codigo + ' ' + (obj.dataProximoLeilao ? this.$options.filters.formatDate(obj.dataProximoLeilao.date) : 'Sem data'),
                value: obj.id
              }
            })
            // !this.multiple && !this.required && list.unshift({label: this.placeholder || 'Selecione', value: null})
            this.asyncdata = list
          })
          .catch((error) => {
            this.loading = false

          })
    },
    relacionarLeilao() {
      if (!this.leilao) {
        this.$uloc.dialog({
          title: 'Necessário selecionar um leilão',
          message: 'Selecione um leilão para relacionar o(s) bem(ns)',
          color: 'negative'
        })
        return
      }
      const leilao = this.leilao
      const itens = this.table.selected
      console.log('Inserindo itens no leilão', itens, leilao)
      this.$uloc.loading.show()
      relacionaItensLeilao(leilao, {
        vendedor: this.table.filters.manager,
        itens
      })
          .then(({data}) => {
            console.log(data)
            this.$uloc.loading.hide()
            this.consultar()
            if (Array.isArray(data.errors) && data.errors.length) {
              this.$uloc.dialog({
                title: 'Importação concluída com erros',
                message: 'Loteamento realizado com sucesso, porém, alguns erros foram retornados: ' + data.errors.join(', ')
              })
            } else{
              this.$uloc.dialog({
                title: 'Importação concluída',
                message: 'Loteamento realizado com sucesso',
                color: 'positive'
              })
            }
          })
          .catch((error) => {
            this.$uloc.loading.hide()
            this.alertApiError(error)
          })
    }
  }
}
</script>

<template>
  <div class="flex col-grow no-wrap globalconfigs-container">
    <transition name="slide" appear>
      <div class="transition-generic w-full">
        <div class="global-config-container">
          <div class="m-t m-b-lg">
            <!--            <h2 class="i-title">Consulta de veículos disponíveis para leilão</h2>
                        <p class="m-b-xxl">Integração com o Resale</p>-->
            <h2 class="i-title">Consulta de bens aptos para leilão</h2>
            <div class="m-t-md">
              Selecione qual vendedor você deseja:<br>
              <erp-select class="m-t-sm" size="2" placeholder="Selecione"
                          :options="vendedores"
                          v-model="table.filters.manager"/>
              <e-btn @click="consultar" class="m-t-xs" label="Consultar"/>
            </div>

            <div class="m-t-lg">
              <div v-if="loading">
                <i class="fa fa-spin fa-spinner"></i> Aguarde enquanto consultamos o webservice do comitente.
              </div>
            </div>
          </div>

          <div class="erp-list list-resale">
            <u-table
                ref="table"
                color="primary"
                :data="filteredServerData"
                :columns="table.columns"
                :visible-columns="visibledColumns"
                :filter="table.busca"
                row-key="id"
                :pagination.sync="table.serverPagination"
                @request="request"
                selection="multiple"
                :selected.sync="table.selected"
                :loading="table.loading"
                class="erp-table erp-table-options table-fin"
                :rows-per-page-options="[100, 300, 500]"
            >
              <!--<template slot="top-right" slot-scope="props">
              </template>-->
              <u-tr slot="header" slot-scope="props">
                <u-th auto-width>
                  <u-checkbox
                      v-model="props.selected"
                      :indeterminate="props.partialSelected"
                      size="sm"
                  />
                </u-th>
                <u-th v-for="col in props.cols" :key="col.name" :props="props">
                  {{ col.label }}
                </u-th>
              </u-tr>

              <u-tr v-on:dblclick.native="abrir(props.row)" class="cursor-pointer" slot="body" slot-scope="props"
                    :props="props" :class="{active: props.row.relacionado}">
                <u-td auto-width>
                  <u-checkbox color="primary" v-model="props.selected" size="xs"/>
                </u-td>
                <u-td key="finalidade" :props="props">
                  {{ props.row.finalidade }}
                </u-td>
                <u-td key="descricao" class="td-limit" style="max-width: 200px; min-width: 200px; width: 200px" :props="props" :title="props.row.descricao">
                  <i class="fa fa-check text-positive" v-if="props.row.relacionado"></i> {{ props.row.nome }}
                </u-td>
                <u-td key="endereco" class="td-limit" style="max-width: 200px; min-width: 200px; width: 200px"  :props="props" :title="props.row.endereco_completo">
                  {{ props.row.endereco_completo }}
                </u-td>
                <u-td key="valorMinimo" :props="props">
                  R$ {{ props.row.valor_venda|moeda }}
                </u-td>
                <u-td key="dataLeilao" :props="props">
                  {{ props.row.data_leilao }}
                </u-td>

                <u-td key="lote" class="td-limit" style="max-width: 60px; min-width: 60px; width: 60px" :props="props">
                  {{ props.row.lote }}
                </u-td>
                <u-td key="rgi" :props="props">
                  {{ props.row.rgi }}
                </u-td>
                <u-td key="matricula" :props="props">
                  {{ props.row.matricula }}
                </u-td>
                <u-td key="carteira_codigo" :props="props">
                  {{ props.row.carteira_codigo }}
                </u-td>
                <u-td key="area_total" :props="props">
                  {{ props.row.area_total }}
                </u-td>

                <u-td class="text-center" key="options" :props="props">
                </u-td>
              </u-tr>
            </u-table>

            <div v-if="table.selected.length > 0">
              <e-row>
                <e-col class="flex items-center m-r-md">
                  <erp-select
                      :loading="loadingLeiloes"
                      placeholder="Selecione o leilão"
                      :options="asyncdata"
                      v-model="leilao"
                      size="2"
                      class="md"
                  ></erp-select>
                </e-col>
                <e-col>
                  <u-btn @click="relacionarLeilao" no-caps push color="primary" icon="plus" icon-type="fa"
                         label="Relacionar/Atualizar bens selecionados ao leilão"/>
                </e-col>
              </e-row>

            </div>
          </div>


        </div>
      </div>
    </transition>
  </div>
</template>

<style lang="stylus" src="../assets/default.styl"></style>
